import './OnBoardingCoordinator.scss'
import '../../../../styles/authFormsStyles.scss'
import React, { useState } from "react";
import { onBoardSignup } from '../../../../utils/constants'
import { validate } from './validate';
import { createPRTCoordinator } from '../../../../utils/ApiClient';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import EnvironmentConfig from '../../../config/EnvironmentConfig';
import useGlobalState from '../../../../context/useGlobalState';

const OnBoardingLogIn = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const { globalErrorHandler, setGlobalSuccessMessage } = useGlobalState()
  const navigate = useNavigate()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const validation = validate(formData);
    if (validation) {
      setErrorMessage(validation)
      setError(true)
    } else {
      try {
        const data = {
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          recaptchaToken: recaptchaToken
        }
        setDisabled(true)
        const response = await createPRTCoordinator(data)
        setGlobalSuccessMessage(response.data.message)
      } catch (error: any) {
        setDisabled(false)
        globalErrorHandler(error)
      }
    }
  }

  const handleNavigation = () => {
    navigate('/login');
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="onboarding-coordinator-page">
      <span className='title'>{onBoardSignup.title}</span>
      <span className='subtitle'>{onBoardSignup.subtitle}</span>
      <form className="signup-form">
        <div className="form-input">
          <div className="input-div">
            <label>{onBoardSignup.firstNameLabel}</label>
            <input
              type="text"
              name="firstname"
              className="signup-page-input"
              placeholder={onBoardSignup.firstNamePlaceHolder}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="input-div">
            <label>{onBoardSignup.lastNameLabel}</label>
            <input
              type="text"
              name="lastname"
              className="signup-page-input"
              placeholder={onBoardSignup.lastNamePlaceHolder}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="input-div">
          <label>{onBoardSignup.emailLabel}</label>
          <input
            type="email"
            name="email"
            className="signup-page-email-input"
            placeholder={onBoardSignup.emailPlaceHolder}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className='captcha-row'>
          <ReCAPTCHA
            sitekey={EnvironmentConfig.recaptchaSiteKey}
            onChange={handleRecaptchaChange}
          />
        </div>
        {error && <div className="inline-response-message error-message-red">{errorMessage}</div>}
        <button
          className={
            disabled
              ? "large-green-button-disabled"
              : "large-maroon-button"
          }
          onClick={handleSubmit}
          disabled={disabled}
        >
          {onBoardSignup.buttonName}
        </button>
      </form>
      <span className="nav-link nav-margin" onClick={handleNavigation}>{onBoardSignup.link}</span>
    </div>
  )
}

export default OnBoardingLogIn