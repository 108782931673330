import { useState } from "react"
import { PRTMangerSettingTabs } from "../../../utils/constants"
import SettingTab from "../../common/SideTabs/prt-manager-settings/SettingsTab"
import EmailNotification from "./EmailNotification"
import Organizations from "./Organizations"
import Specialists from "./Specialists"
import Coordinators from "./AllUsers"
import './SettingsPage.scss'

const Settings = () => {
  const [activeTab, setActiveTab] = useState(PRTMangerSettingTabs.EMAIL_NOTIFICATION)
  const tabs = Object.values(PRTMangerSettingTabs)
  
  return (
    <div className="settings-page">
      <SettingTab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="setting-content">
        {
          activeTab === PRTMangerSettingTabs.EMAIL_NOTIFICATION && <EmailNotification />
        }
        {
          activeTab === PRTMangerSettingTabs.ORGANIZATION  && <Organizations />
        }
        {
          activeTab === PRTMangerSettingTabs.SPECIALIST  && <Specialists />
        }
        {
          activeTab === PRTMangerSettingTabs.ALL_USERS && <Coordinators />
        }
      </div>
    </div>
  )
}

export default Settings