import React from "react";
import { storageKeys, userType } from "../../../utils/constants";
import LogIn from "../login/index";
import FixedHeaderUserLayout from '../../layout/FixedHeaderUserLayout'
import PRTManagerDashboard from "../prt-manager/PRTManagerDashboard";
import PRTDashboardHome from "../prt-dashboard/PRTDashboardHome";
import '../../../styles/globalStyles.scss'

const Home: React.FC = () => {
  const token: string | null = localStorage.getItem(storageKeys.TOKEN);
  const userTypeName: string | null = localStorage.getItem(storageKeys.USER_TYPE);
  if (token) {
    if (userTypeName) {
      if (userTypeName === userType.PRT_MANAGER) {
        return <FixedHeaderUserLayout children={<PRTManagerDashboard />} />
      }
      else return <FixedHeaderUserLayout children={<PRTDashboardHome />} />
    }
  } else {
    return (
      <LogIn />
    );
  }
  return null;
};

export default Home;
