import { FC, useState } from "react";
import GlobalContext from "./globalContext";
import { clearLocalStorageExceptOID } from "../utils/clearStorageData";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { storageKeys } from "../utils/constants";

type GlobalStateProviderProps = {
  children: React.ReactNode;
};

interface GlobalState {
  globalError: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  globalSuccessMessage: string;
  setServiceType: (serviceType: string[] | null) => void;
  setGlobalError: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setGlobalSuccessMessage: (message: string) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
}

const GlobalState: FC<GlobalStateProviderProps> = (props) => {
  const navigate: (path: string) => void = useNavigate();
  const location = useLocation();
  const pathName: string = location.pathname;
  const [globalError, setGlobalError] = useState<string>("");
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState<string>("");
  const [serviceType, setServiceType] = useState<string[] | null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [menu, setMenu] = useState<boolean>(false);

  const globalErrorHandler = (error: any): void => {
    if (error.response?.status === 401) {
      clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      navigate(`/login?redirect_url=${pathName}`);
    } else if (error.response?.status === 400) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else if (error.response?.status === 404) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else {
      setGlobalError(error.message);
      Sentry.captureException(error);
    }
  };

  const globalState: GlobalState = {
    globalError,
    serviceType,
    loader,
    menu,
    globalSuccessMessage,
    setServiceType,
    setGlobalError,
    globalErrorHandler,
    setGlobalSuccessMessage,
    setLoader,
    setMenu,
  };

  return (
    <GlobalContext.Provider value={globalState}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;