import React from 'react'
import { Dialog, DialogContent } from '@mui/material';
import './ConfirmDialogBox.scss'

interface DialogProps {
    title: string;
    content: string;
    confirmLabel: string;
    onConfirm: () => void;
    handleClose: () => void;
    openDialogBox: boolean;
    buttonDisabled : boolean
}

const ConfirmDialogBox: React.FC<DialogProps> = ({ title, content, handleClose, openDialogBox, onConfirm, confirmLabel, buttonDisabled }) => {
    const handleDialogClose = (event: React.SyntheticEvent, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        handleClose(); 
    };
    return (
        <Dialog onClose={handleDialogClose} open={openDialogBox} disableEscapeKeyDown >
            <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', columnGap: '10px' }}>
                <div className='changing-status-dialog-component'>
                    <span className='content-title'>{title}</span>
                    <p className='dialog-content'>{content}</p>
                    <div className='dialog-buttons'>
                        <button className='dialog-cancel-btn' onClick={handleClose}>
                            {"Cancel"}
                        </button>
                        <button className={buttonDisabled ? 'disable-assign-button' :'assign-button'} onClick={onConfirm}>
                            {confirmLabel}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialogBox