import React from 'react'
import { MenuItem } from '@mui/material'
import { claimEapxCase, reOpenEapxCase } from '../../../../utils/ApiClient'
import useGlobalState from '../../../../context/useGlobalState'
import './CaseList.scss'
import { PRTUserCasesType, storageKeys, userType } from '../../../../utils/constants'
import { ApiEAPXCase } from '../../../../interfaces/ApiTypes'

interface CaseListProps {
    caseList: ApiEAPXCase[];
    setCaseClaimed: (claim: boolean) => void;
    setCaseLoading: (load: boolean) => void;
    setSearchTerm: (term: string) => void;
    setOpenList: (open: boolean) => void;
    noCase: boolean;
    searchTerm: string;
    casesTypeName: string;
    setCasesTypeName: (name: string) => void;
    setCaseReopen: (open: boolean) => void;
    handleCase?: (fileNo: number, id?: string) => void;
}

const CaseList: React.FC<CaseListProps> = ({ caseList, setCaseClaimed, setCaseLoading, setSearchTerm, setOpenList, noCase, searchTerm, casesTypeName, setCasesTypeName, setCaseReopen, handleCase }) => {
    const { globalErrorHandler } = useGlobalState()
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    const isManager = userTypeName !== userType.PRT_USER

    const handleClaimCase = async (fileNo: number): Promise<void> => {
        if (casesTypeName === PRTUserCasesType.CLOSED.label) {
            try {
                await reOpenEapxCase(fileNo)
                setCaseClaimed(true);
                setSearchTerm("");
                setOpenList(false);
                setCasesTypeName(PRTUserCasesType.MY_CASES.label)
                setCaseLoading(true);
                if (setCaseReopen) {
                    setCaseReopen(true);
                }
            } catch (error) {
                globalErrorHandler(error)
                setCaseClaimed(false)
            }
        } else {
            try {
                setCaseClaimed(false)
                await claimEapxCase(fileNo)
                setCaseClaimed(true);
                setSearchTerm("");
                setOpenList(false);
                setCaseLoading(true);
            } catch (error) {
                globalErrorHandler(error)
                setCaseClaimed(false)
            }
        }
    }

    const handleSelectedCase = (fileNo: number) => {
        if(!isManager) return
        handleCase?.(fileNo);
    }

    return (
        <div className='case-list-component'>
            {
                noCase ? <MenuItem><span className='no-cases menu-items'>"#{searchTerm}" {"Case not found"}</span></MenuItem> : <>
                    {
                        caseList?.map((item, index) => {
                            return (
                                <MenuItem key={"case" + index} className='menu-items' onClick={() => handleSelectedCase(item.eapx_file_number)}>
                                    <div className='info-col'>
                                        <div className='file-info'>
                                            <span className='file-name'>#{item.eapx_file_number}</span>
                                        </div>
                                        <div className='file-info'>
                                            <span className='last-modify-date'>{item.company_name}</span>
                                        </div>
                                    </div>
                                    {
                                        !isManager && <button className={'claim-button'}
                                            onClick={() => handleClaimCase(item.eapx_file_number)}>
                                            {casesTypeName === PRTUserCasesType.CLOSED.label ? "Re-open" : "Claim"}
                                        </button>
                                    }
                                </MenuItem>)
                        })
                    }</>
            }
        </div>
    )
}

export default CaseList