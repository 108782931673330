import React from "react";
import emailIcon from '../../../assests/icons/email.png'
import orgIcon from '../../../assests/icons/organization.png'
import cordIcon from '../../../assests/icons/coordinator.png'
import speclIcon from '../../../assests/icons/specialist.png'
import { MenuItem } from "@mui/material";
import './SettingsTab.scss'

interface SettingTabProps {
  tabs: any[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const SettingTab: React.FC<SettingTabProps> = ({ tabs, activeTab, setActiveTab }) => {

  const imageSrc = (index: number) => {
    const images = [emailIcon, orgIcon, cordIcon, speclIcon]
    return images[index]
  }

  return (
    <div className="settings-tab-component">
      {tabs.map((tab, index) => (
        <MenuItem
          onClick={() => setActiveTab(tab)}
          key={"tab"+index}
          className={`${activeTab === tab ? "active-tab" : "tabs"}`}>
          <img src={imageSrc(index)} />
          <span>{tab}</span>
        </MenuItem>
      ))}
    </div>
  );
};

export default SettingTab;
