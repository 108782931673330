import React, { useState } from 'react'
import { PRTUserCasesType, storageKeys, userType, } from '../../../utils/constants'
import { updateEapxCase } from '../../../utils/ApiClient'
import useGlobalState from '../../../context/useGlobalState';
import InlineError from '../../common/Error/InlineError';

interface NoteProps {
    notes: string;
    setNotes: (note: string) => void;
    setSuccessMessage?: (message: string) => void;
    casesTypeName?: string;
    fileNo: number | undefined
}

const NoteSection: React.FC<NoteProps> = ({ notes, setNotes, setSuccessMessage, casesTypeName, fileNo }) => {
    const { globalErrorHandler } = useGlobalState()
    const [emptyNotes, setEmptyNotes] = useState<boolean>(false)
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    const isManagerUser = userTypeName === userType.PRT_MANAGER
    const isClosedCase = casesTypeName === PRTUserCasesType.CLOSED.label

    const saveNotes = async (): Promise<void> => {
        if (!notes) {
            setEmptyNotes(true)
        } else {
            let note = notes
            const data = { notes: notes }
            try {
                const response = await updateEapxCase(fileNo ?? 0, data)
                if (setNotes) setNotes(note)
                if (setSuccessMessage) setSuccessMessage(response.data.message)
                setEmptyNotes(false)
            } catch (error) {
                globalErrorHandler(error)
            }
        }
    }
    return (
        <div className='notes-section'>
            <textarea
                rows={10}
                placeholder="Notes"
                className='notes-textarea'
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={isManagerUser || isClosedCase}
            />
            {
                !(isManagerUser || isClosedCase) && (
                    <div className='button-section'>
                        <button className='save-notes' onClick={saveNotes}>{"Save"}</button>
                    </div>
                )
            }
            {
                emptyNotes && <InlineError errorMessage='Please add notes' />
            }
        </div>
    )
}

export default NoteSection