import { storageKeys, userType } from "../../../utils/constants";
import FixedHeaderUserLayout from '../../layout/FixedHeaderUserLayout'
import PRTManagerDashboard from "../prt-manager/PRTManagerDashboard";
import PRTDashboardHome from "../prt-dashboard/PRTDashboardHome";

const DynamicDashboardRoute = () => {
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    if (userTypeName === userType.PRT_MANAGER) {
        return <FixedHeaderUserLayout children={<PRTManagerDashboard />} />;
    } else {
        return <FixedHeaderUserLayout children={<PRTDashboardHome />} />;
    }
};

export default DynamicDashboardRoute