import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalState from "../context/globalState";
import { userType } from "../utils/constants";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Home from "../component/pages/home/index";
import Login from "../component/pages/login/index";
import VerifyMagicLink from "../component/pages/login/MagicTokenVerification";
import PageNotFound from "../component/pages/page-not-found/index";
import Settings from "../component/pages/prt-manager-settings/index";
import OnBoardingSignUp from "../component/pages/prt-manager-settings/on-boarding/index";
import ActivateCoordinator from "../component/pages/prt-manager-settings/on-boarding/ActivateCoordinator";
import DynamicDashboardRoute from "../component/pages/dynamic-dashboard-route";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <GlobalState>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/verify/:user_id/:magic_token"
            element={<VerifyMagicLink />}
          />

          <Route path="/dashboard" element={<DynamicDashboardRoute />} />
          <Route path="/prt-settings" element={<AuthenticatedRoute allowedType={userType.PRT_MANAGER} children={<Settings />} />} />
          <Route path="/onboarding" element={<OnBoardingSignUp />} />
          <Route path="/activate/prt-coordinator/:user_id/:magic_token" element={<ActivateCoordinator />} />

          {/* GENERAL ROUTES */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </GlobalState>
    </BrowserRouter>
  );
};

export default AppRouter;
