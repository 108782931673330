import React, { useState } from 'react';
import { MenuItem, Box, Button, Menu } from '@mui/material';
import TickButton from '../../assests/icons/check-button.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { updateEapxCase } from '../../../utils/ApiClient';
import useGlobalState from '../../../context/useGlobalState';
import { isProviderApproved } from '../../../utils/helperFunctions';
import { caseStatusChangeDialog, storageKeys, userType, SideBarTabItemType1, SideBarTabItemType2, PRTUserCasesType, CaseStatusType, CaseStatus, EAPXCaseStatusEnum } from '../../../utils/constants';
import ConfirmationDialogBox from '../DialogBox/ConfirmDialogBox';
import './DropdownWithConfirmationBar.scss'
import { AdditionalProvider } from '../../pages/prt-dashboard/PRTDashboardHome';

interface CustomSelectWithButtonsProps {
  options: {} | [];
  name: string;
  stateChange: (state: string) => void;
  state: any;
  showButtons: boolean;
  fileNo: number | undefined;
  showPopUp: boolean;
  dialogTitle?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  activeCaseUUID?: string;
  additionalProviders?: AdditionalProvider;
  setSuccessMessage?: (message: string) => void;
  setCaseStatus?: (state: string) => void;
  casesTypeName: string;
  caseStatus?: string;
  specialist?: string;
  setError?: (message: string) => void;
}

const DropdownWithConfirmationBar: React.FC<CustomSelectWithButtonsProps> = ({ casesTypeName, options, name, stateChange, state, caseStatus, showButtons, fileNo, showPopUp, additionalProviders, activeCaseUUID, setSuccessMessage, setCaseStatus, specialist, setError }) => {
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
  const isManager = userTypeName === userType.PRT_MANAGER
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuDisabled = (casesTypeName === "Closed" && name !== "eapx_file_status") || isManager ? true : false
  const [valueToSend, setValueToSend] = useState<string>("")
  const { globalErrorHandler } = useGlobalState()
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelButtonPressed = () => {
    if (name === "eapx_file_status") {
      if (caseStatus) {
        stateChange(caseStatus)
        if (setCaseStatus) {
          setCaseStatus(caseStatus)
        }
      }
      setOpenDialogBox(false)
      setAnchorEl(null)
    } else {
      stateChange(specialist ?? "")
      setAnchorEl(null)
    }
  };

  const updateCase = async (option: string): Promise<void> => {
    const data = { [name === "Assigned Specialist" ? "assigned_specialist" : name]: option }
    setIsDialogButtonDisabled(true)
    try {
      const response = await updateEapxCase(fileNo ?? 0, data)
      if (setCaseStatus) {
        setCaseStatus(state)
      }
      if (setSuccessMessage) {
        setSuccessMessage(response.data.message);
      }
      setAnchorEl(null);
      setIsDialogButtonDisabled(false)
      setOpenDialogBox(false)
    } catch (error) {
      if (caseStatus && name === "eapx_file_status") {
        stateChange(caseStatus)
      } else {
        stateChange("")
      }
      stateChange("")
      setIsDialogButtonDisabled(false)
      globalErrorHandler(error)
      setAnchorEl(null);
    }
  }

  const handleSave = async (state: string): Promise<void> => {
    if (name === "eapx_file_status" && state === EAPXCaseStatusEnum.ACCEPTED) {
      const result = isProviderApproved(additionalProviders, activeCaseUUID ?? "")
      if (!result) {
        if (showPopUp) {
          setOpenDialogBox(true)
        }
      } else {
        updateCase(state)
      }
    } else {
      if (!state && !specialist) {
        setError?.("Please select Assign specialist")
        handleClose()
      }
      else updateCase(state)
    }
  };

  const handleMenuItemClick = (value: any) => {
    if (value.full_name) {
      setValueToSend(value.id)
      stateChange(value.full_name)
    } else {
      if (value.label) {
        setValueToSend(value.value)
      } else {
        setValueToSend(value)
      }
      stateChange(value.label ? value.label : value);
    }
  };

  return (
    <Box>
      <Button
        aria-controls="assigned-specialist-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          textAlign: 'left',
          backgroundColor: 'white',
          color: '#000000',
        }}
        sx={{ textTransform: 'none' }}
        variant='contained'
        color='info'
        endIcon={<ArrowDropDownIcon />}
      >
        {state || name}
      </Button>
      <Menu
        id="assigned-specialist-menu"
        anchorEl={anchorEl}
        open={isMenuDisabled ? false : Boolean(anchorEl)}
        onClose={handleClose}
        defaultValue={state}
        MenuListProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : '100',
            paddingBottom: '0px'
          }
        }}
      >
        {
          options &&
          Object.values(options).map(
            (item: SideBarTabItemType1 | SideBarTabItemType2 | CaseStatusType, index: number) => {
              const isEapxFileStatus =
                name === "eapx_file_status" &&
                casesTypeName === PRTUserCasesType.MY_CASES.label &&
                index === 3;
              const disableFirstThreeForEapxFileStatus =
                name === "eapx_file_status" &&
                casesTypeName === PRTUserCasesType.CLOSED.label &&
                index < 3;

              let displayLabel: string;
              if (typeof item === "string") {
                displayLabel = item;
              } else if ("label" in item) {
                displayLabel = item.label;
              } else {
                displayLabel = item.full_name;
              }
              const isSelected = state === displayLabel;
              return (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItemClick(item)}
                  disabled={isEapxFileStatus || disableFirstThreeForEapxFileStatus}
                  style={isSelected ? { fontWeight: "600" } : {}}
                >
                  {displayLabel}
                  {isSelected && (
                    <img src={TickButton} alt="checked" style={{ marginLeft: "auto" }} />
                  )}
                </MenuItem>
              );
            }
          )
        }

        <Box>
          {
            showButtons && <div className={'button-area'}>
              <button onClick={handleCancelButtonPressed} className='cancel-btn'>
                {"Cancel"}
              </button>
              <button onClick={() => handleSave(valueToSend)} className={isDialogButtonDisabled ? 'processing-btn' : 'save-btn'} disabled={isDialogButtonDisabled}>
                {"Save"}
              </button>
            </div>
          }
        </Box>
        <ConfirmationDialogBox
          title={caseStatusChangeDialog.title}
          content={caseStatusChangeDialog.content}
          onConfirm={() => updateCase(valueToSend)}
          handleClose={handleCancelButtonPressed}
          confirmLabel={"Confirm"}
          openDialogBox={openDialogBox}
          buttonDisabled={isDialogButtonDisabled} />
      </Menu>
    </Box>
  );
};

export default DropdownWithConfirmationBar;
