import { useEffect, useRef, useState } from "react"
import { getOrganizationList } from "../../../utils/ApiClient";
import SearchBox from "../../common/SearchBox/SearchBox"
import Loader from "../../common/Loader/Loader"
import { PRT_Organizations } from "../../../utils/constants";
import DataList from "../../common/PRT-Settings/DataList";
import useGlobalState from "../../../context/useGlobalState";
import InlineError from "../../common/Error/InlineError";

const Organizations = () => {
  const [organizations, setOrganizations] = useState([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { globalErrorHandler } = useGlobalState()
  const [searchTermAvailable, setSearchTermAvailable] = useState<boolean>(false)
  const [noData, setNodata] = useState<string>("")
  const effectRef = useRef(false)

  const fetchOrganizations = async (searchTerm: string): Promise<void> => {
    try {
      const response = await getOrganizationList(searchTerm ?? "")
      if (response.data.data.organizations.length === 0) {
        setNodata(searchTerm ? `Organization “${searchTerm}” Not Found` : "No Organizations found")
        setLoading(false)
      } else
        setNodata("")
      setOrganizations(response.data.data.organizations)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false)
      }, 5000)
    }
  }, [error])

  useEffect(() => {
    if (effectRef.current === false) {
      fetchOrganizations("")
    }
    return () => { effectRef.current = true }
  }, [])

  const handleSearchClick = async (): Promise<void> => {
    if (searchTermAvailable) {
      await fetchOrganizations("");
      setSearchTerm("")
      setSearchTermAvailable(false);
      return;
    } else {
      if (!searchTerm) {
        setError(true)
        return
      }
      setError(false)
      await fetchOrganizations(searchTerm);
      setSearchTermAvailable(true)
    }
  }

  return (
    <div>
      {
        loading ? <div className="loader-box"><Loader /></div> : <div className="setting-component">
          <div className="setting-grid">
            <div className="setting-info">
              <span className="setting-title">{PRT_Organizations.title}</span>
              <span className="setting-desciption">{PRT_Organizations.description}</span>
            </div>
            <div className="searc-box-section">
              <SearchBox value={searchTerm} setValue={setSearchTerm} onClick={handleSearchClick} searchTermAvailable={searchTermAvailable}/>
            </div>
          </div>
          <div className="add-section">
            <span className="input-area">{"Organization Names"}</span>
            <span className="input-area title-margin">{"OrgID"}</span>
          </div>
          {error && <InlineError errorMessage="Please enter Organization" />}
          {noData ? <span className="no-data">{noData}</span> : <DataList list={organizations} hideButtons={true} />}
        </div>
      }
    </div>
  )
}

export default Organizations