import React from "react";
import { DialogContent } from "@mui/material";
import { common } from "../../../utils/constants";
import Dialog from '@mui/material/Dialog';
import "./Loader.scss";

const Loader: React.FC = () => {
  return (
    <div className="loader-component">
      <Dialog
        open={true}>
        <DialogContent>
          <div className="loader-component">
            <div className="loader"></div>
            <span>{common.LOADING}</span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Loader;
