import { useContext } from "react";
import GlobalContext, { GlobalState } from "./globalContext";

const useGlobalState = (): GlobalState => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }

  return context;
};

export default useGlobalState;
