import React from 'react'
import SearchIcon from '../../assests/icons/search-icon-red.png'
import CancelIcon from '../../assests/icons/cancel.png'
import './SearchBox.scss'

interface SearchBoxProps {
  value: string;
  setValue: (term: string) => void;
  onClick: () => void;
  searchTermAvailable: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, setValue, onClick, searchTermAvailable }) => {
  return (
    <div className='search-box-component'>
      <input value={value} onChange={(e) => setValue(e.target.value)} placeholder='Search'></input>
      <img src={!searchTermAvailable ? SearchIcon : CancelIcon} alt='search' className='search-icon' onClick={onClick} />
    </div>
  )
}

export default SearchBox